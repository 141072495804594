import { render, staticRenderFns } from "./DetailsBuilding.vue?vue&type=template&id=60bb063f&scoped=true&"
import script from "./DetailsBuilding.vue?vue&type=script&lang=js&"
export * from "./DetailsBuilding.vue?vue&type=script&lang=js&"
import style0 from "./DetailsBuilding.vue?vue&type=style&index=0&id=60bb063f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bb063f",
  null
  
)

export default component.exports