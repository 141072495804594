<template>
  <section>
    <div class="row">
      <div class="col-12 mb-2">
        <h1 class="h3">Características</h1>
        <button
          v-show="isOwner(building)"
          class="btn btn-primary"
          @click="edit(building.id)"
        >
          Editar
        </button>
      </div>

      <!-- Card 1 -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <img class="img-fluid" :src="buildingImage" alt="" />
              </div>
              <div class="col-sm-8">
                <p>
                  Referencia Catastral: {{ this.building.referencia_catastral }}
                </p>
                <p>Denominación: {{ this.building.denominacion }}</p>
                <p>Localidad: {{ this.building.localidad }}</p>
                <p>Uso: {{ this.building.uso }}</p>
                <p>
                  Año de Construcción: {{ this.building.construccion_year }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- . Card 1 -->

      <!-- Card 2 -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <p>
                  Autoridad Responsable:
                  {{ this.building.autoridad_responsable }}
                </p>
                <p>
                  Superficie Habitable: {{ this.building.superficie_habitable }}
                </p>
                <p>Numero de plantas: {{ this.building.numero_de_plantas }}</p>
                <p>Configuración: {{ this.building.configuracion }}</p>
                <p>Zona Climática: {{ this.building.zona_climatica }}</p>
              </div>
              <div class="col-sm-6">
                <div>
                  <small>
                    Coordenadas: Latitud {{ mapCoordinates.lat }}, Longitud
                    {{ mapCoordinates.lng }}
                  </small>
                  <GmapMap
                    class="map"
                    :center="this.mapCoordinates"
                    :zoom="zoom"
                    ref="mapRef"
                  >
                    <GmapMarker
                      :position="{
                        lat: this.mapCoordinates.lat,
                        lng: this.mapCoordinates.lng,
                      }"
                      :draggable="false"
                    ></GmapMarker>
                  </GmapMap>
                </div>
              </div>
            </div>
            <div v-if="!statsError" class="row">
              <div class="col-sm-6">
                <b-table :fields="fields" :items="parsedFieldsTable1"></b-table>
              </div>
              <div class="col-sm-6">
                <b-table :fields="fields" :items="parsedFieldsTable2"></b-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Card 3 -->
      <div v-if="!statsError" class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <div v-show="ignoredBuildingsFields.length !== 0">
              <p class="text-danger">
                Los siguientes edificios no tienen calificación energética y han
                sido retirados del informe
              </p>
              <ul class="text-danger">
                <li v-for="(item, key) in ignoredBuildingsFields" :key="key">
                  {{ item.denominacion }}
                </li>
              </ul>
            </div> -->
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 variant="primary"
                    >Edificios comparados</b-button
                  >
                </b-card-header>
                <b-collapse
                  visible
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-table
                      :fields="buildingsFields"
                      :items="comparedBuildingsFields"
                    >
                      <template #cell(id)="data">
                        {{ data.item.id }}
                      </template>
                    </b-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <br />
            <small>
              Haga click en cada línea para poder visualizar la gráfica
              asociada.
            </small>
            <b-list-group>
              <b-list-group-item
                button
                @click="showStats(0)"
                :active="selectedRow === 0"
              >
                <div class="row">
                  <div class="col-10">Demanda energética de calefacción</div>
                  <div
                    class="col-2 font-weight-bold"
                    :class="calPercent > 0 ? 'text-danger' : 'text-success'"
                  >
                    {{ calPercent }} %
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item
                button
                @click="showStats(1)"
                :active="selectedRow === 1"
                ><div class="row">
                  <div class="col-10">Demanda energética de refrigeración</div>
                  <div
                    class="col-2 font-weight-bold"
                    :class="refPercent > 0 ? 'text-danger' : 'text-success'"
                  >
                    {{ refPercent }} %
                  </div>
                </div></b-list-group-item
              >
              <b-list-group-item
                button
                @click="showStats(2)"
                :active="selectedRow === 2"
                ><div class="row">
                  <div class="col-10">Indicador global de emisiones</div>
                  <div
                    class="col-2 font-weight-bold"
                    :class="emiPercent > 0 ? 'text-danger' : 'text-success'"
                  >
                    {{ emiPercent }} %
                  </div>
                </div></b-list-group-item
              >
              <b-list-group-item
                button
                @click="showStats(3)"
                :active="selectedRow === 3"
                ><div class="row">
                  <div class="col-10">
                    Indicador global consumo de energía no renovable
                  </div>
                  <div
                    class="col-2 font-weight-bold "
                    :class="conPercent > 0 ? 'text-danger' : 'text-success'"
                  >
                    {{ conPercent }} %
                  </div>
                </div></b-list-group-item
              >
            </b-list-group>

            <BarChart
              v-if="this.selectedRow !== null"
              class="chart"
              :data-set="chartData"
              :averageValue="averageValue"
              :marginLeft="100"
              :marginTop="100"
              :marginBottom="100"
              :yLabel="yLabel"
              :tick-count="5"
              :bar-padding="0.5"
            />

            <br />

            <br />

            <table class="table" v-show="this.selectedRow !== null">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th colspan="2">
                    Variación estimada de la clasificación global
                  </th>
                </tr>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Ahorro estimado</th>
                  <th scope="col">Costes</th>
                  <th scope="col">Emisiones</th>
                  <th scope="col">Consumo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(imp, index) in selectedArray" :key="index">
                  <th scope="row">{{ imp.key }}</th>
                  <td>{{ imp.data.ahorro }} %</td>
                  <td>
                    {{ Math.round(imp.data.coste.min / 100) * 100 }} -
                    {{ Math.round(imp.data.coste.max / 100) * 100 }} €
                  </td>
                  <td>
                    <img
                      :src="
                        scoreParser(imp.data.classifications.emissions.base)
                      "
                      alt=""
                    />
                    <img src="../assets/arrow1.png" alt="" />
                    <img
                      :src="
                        scoreParser(imp.data.classifications.emissions.estimate)
                      "
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      :src="
                        scoreParser(imp.data.classifications.consumption.base)
                      "
                      alt=""
                    />
                    <img src="../assets/arrow1.png" alt="" />
                    <img
                      :src="
                        scoreParser(
                          imp.data.classifications.consumption.estimate
                        )
                      "
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-show="ignoredBuildingsFields.length !== 0"
              class="accordion"
              role="tablist"
            >
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 variant="primary"
                    >Edificios excluidos del informe</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion-2"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-table
                      :fields="buildingsFields"
                      :items="ignoredBuildingsFields"
                    >
                      <template #cell(id)="data">
                        {{ data.item.id }}
                      </template>
                    </b-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <!-- . Card 3 -->
    </div>
  </section>
</template>

<script>
import api from "@/api.js";
import store from "@/store";
import BarChart from "@/components/BarChart.vue";
export default {
  name: "DetailsBuilding",
  components: {
    BarChart,
  },
  data: () => ({
    statsError: false,
    building: {},
    buildingImage: null,
    buildingStats: {},
    calImprovementsArray: [],
    refImprovementsArray: [],
    emiImprovementsArray: [],
    conImprovementsArray: [],
    ignoredBuildingsFields: [],
    comparedBuildings: [],
    comparedBuildingsFields: [],

    calPercent: null,
    refPercent: null,
    emiPercent: null,
    conPercent: null,

    map: null,
    zoom: 7,
    mapCoordinates: {
      lat: 0,
      lng: 0,
    },

    buildingsFields: [
      "id",
      { key: "denominacion", label: "Denominación" },
      { key: "construccion_year", label: "Año" },
    ],

    parsedFieldsTable1: [],
    parsedFieldsTable2: [],

    fields: ["Campo", "Valor"],

    selectedRow: null,
  }),

  async created() {
    await this.getBuilding();
    await this.getStats();
  },

  computed: {
    chartData() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.graph_values;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.graph_values;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.graph_values;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.graph_values;
      } else return [];
    },
    yLabel() {
      if (this.selectedRow === 0) {
        return "kWh/m2año";
      } else if (this.selectedRow === 1) {
        return "kWh/m2año";
      } else if (this.selectedRow === 2) {
        return "kgCO2/m2año";
      } else if (this.selectedRow === 3) {
        return "kWh/m2año";
      } else return "";
    },
    averageValue() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.average;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.average;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.average;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.average;
      } else return [];
    },

    tableData() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.improvements;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.improvements;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.improvements;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.improvements;
      } else return [];
    },

    selectedArray() {
      if (this.selectedRow === 0) {
        return this.calImprovementsArray;
      } else if (this.selectedRow === 1) {
        return this.refImprovementsArray;
      } else if (this.selectedRow === 2) {
        return this.emiImprovementsArray;
      } else if (this.selectedRow === 3) {
        return this.conImprovementsArray;
      } else return [];
    },
  },

  methods: {
    async getBuilding() {
      const { data } = await api.get(`buildings/${this.$route.params.id}`);
      this.building = data;
      this.buildingImage =
        this.building.imagen || require("../assets/no-photo.png");
      if (this.building.ubicacion) {
        this.mapCoordinates = {
          lat: Number(this.building.ubicacion.latitud),
          lng: Number(this.building.ubicacion.longitud),
        };
      }
    },

    async getStats() {
      try {
        const { data } = await api.get(
          `building_stats/?building_id=${this.$route.params.id}`
        );
        this.buildingStats = data;
      } catch {
        this.statsError = true;
        console.log("Error in catch");
      }

      if (!this.buildingStats.errors && !this.statsError) {
        this.parseBuildingStats();

        this.percentParser();

        this.comparedBuildings = JSON.parse(
          this.buildingStats.compared_buildings
        );
        this.comparedBuildingsFields = this.comparedBuildings.map((item) => {
          return { id: item.pk, ...item.fields };
        });

        const ignored = JSON.parse(this.buildingStats.rechazados);
        this.ignoredBuildingsFields = ignored.map((item) => {
          return { id: item.pk, ...item.fields };
        });

        Object.entries(this.buildingStats.calefaccion.improvements).forEach(
          ([key, data]) => {
            this.calImprovementsArray.push({ key, data });
          }
        );

        Object.entries(this.buildingStats.refrigeracion.improvements).forEach(
          ([key, data]) => {
            this.refImprovementsArray.push({ key, data });
          }
        );

        Object.entries(this.buildingStats.emisiones.improvements).forEach(
          ([key, data]) => {
            this.emiImprovementsArray.push({ key, data });
          }
        );

        Object.entries(this.buildingStats.consumo.improvements).forEach(
          ([key, data]) => {
            this.conImprovementsArray.push({ key, data });
          }
        );

        this.statsError = false;
      } else {
        this.statsError = true;
        console.log("ERRORRS!");
      }
    },

    getFieldFromValueField(field) {
      if (field === "heating") return "Calefacción";
      else if (field === "acs") return "ACS";
      else if (field === "refrigeration") return "Refrigeración";
      else if (field === "ligthing") return "Iluminación";
      else return "Ninguna";
    },

    parseBuildingStats() {
      this.parsedFieldsTable1 = [
        {
          Campo: "Demanda energética para calefacción",
          Valor: this.buildingStats.building.calefaccion,
        },
        {
          Campo: "Demanda energética para refrigeración",
          Valor: this.buildingStats.building.refrigeracion,
        },
        {
          Campo: "Emisiones globales",
          Valor: this.buildingStats.building.emisiones,
        },
        {
          Campo: "Consumo de energía no renovable",
          Valor: this.buildingStats.building.consumo,
        },
        {
          Campo: "Principal causa de emisiones",
          Valor: this.getFieldFromValueField(
            this.buildingStats.building.principal_causa_emisiones
          ),
        },
        {
          Campo: "Principal causa de consumo",
          Valor: this.getFieldFromValueField(
            this.buildingStats.building.principal_causa_consumo
          ),
        },
      ];

      this.parsedFieldsTable2 = [
        {
          Campo: "Principal instalacion de calefacción",
          Valor: this.buildingStats.building.principal_instalacion_calefaccion,
        },
        {
          Campo: "Principal instalacion de refrigeración",
          Valor: this.buildingStats.building
            .principal_instalacion_refrigeracion,
        },
        {
          Campo: "Principal instalacion de ACS",
          Valor: this.buildingStats.building.principal_instalacion_acs,
        },
        {
          Campo: "Potencia instalada de iluminación",
          Valor: this.buildingStats.building.potencia_instalada_iluminacion,
        },
        {
          Campo: "Renovables: térmica",
          Valor: this.buildingStats.building.renovables_termica,
        },
        {
          Campo: "Renovables: fotovoltaica",
          Valor: this.buildingStats.building.renovables_fotovoltaica,
        },
        {
          Campo: "Medidas pasivas",
          Valor: this.buildingStats.building.medidas_pasivas ? "Sí" : "No",
        },
      ];
    },

    showStats(index) {
      this.selectedRow = index;
    },

    scoreParser(score) {
      switch (score) {
        case "A":
          return require("../assets/score_a.png");
        case "B":
          return require("../assets/score_b.png");
        case "C":
          return require("../assets/score_c.png");
        case "D":
          return require("../assets/score_d.png");
        case "E":
          return require("../assets/score_e.png");
        case "F":
          return require("../assets/score_f.png");
        case "G":
          return require("../assets/score_g.png");
        default:
          return "";
      }
    },

    percentParser() {
      this.calPercent = this.buildingStats.calefaccion.percent;
      this.refPercent = this.buildingStats.refrigeracion.percent;
      this.emiPercent = this.buildingStats.emisiones.percent;
      this.conPercent = this.buildingStats.consumo.percent;
    },

    isOwner(building) {
      const isOwner = building.creator
        ? store.getters["auth/getUserEmail"] === building.creator.email
        : false;
      return isOwner;
    },

    edit(id) {
      this.$router.push({
        name: "building",
        params: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 300px;
}
.chart {
  margin: 40px auto 0;
  display: block;
}

.positiveColor {
  color: red;
}
</style>
